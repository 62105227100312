/* eslint-disable indent */
import { Component, Watch } from 'vue-property-decorator'
import { BaseComponent, BaseView } from '@badam/vue-components'
import { _T, sdk } from '@badam/common-utils'
import { USER_TYPE_CUSTOM } from '@/api/index'
import { goToAuditRoom, routerPush, changeBalootIcon } from '@/utils/index'
import { getUserInfo, removeLoginType, removeToken, removeUserInfo } from '@/utils/token'
import { reportH5PayClick, reportH5PayLoginWin } from '@/utils/step-report-wrap'
import store from '@/store'
import styles from './pc.module.scss'

interface Props { }
@Component({})
class loginNav extends BaseComponent<Props> {
  curTab = 0

  tabs = getUserInfo().role === USER_TYPE_CUSTOM ? [_T('审核'), _T('首页')] : [_T('首页')]

  userData: any = null

  renderTab(item: any, index: number) {
    return this.tabs.length > 0 ? (
      <BaseView
        class={styles.tabC}
        nativeOnClick={() => {
          this.curTab = index
          if (item === _T('首页')) {
            routerPush({ name: 'index' })
          } else if (item === _T('审核')) {
            goToAuditRoom()
          }
        }}
      >
        <BaseView class={[styles.tab, this.curTab === index ? '' : 'no']}>{item}</BaseView>
        {this.tabs.length === 1 ? null : (
          <BaseView
            class={styles.divider}
            styles={{
              opacity: this.curTab === index ? 1 : 0,
            }}
          ></BaseView>
        )}
      </BaseView>
    ) : null
  }

  mounted() {
    this.update()
  }

  update() {
    const data = getUserInfo()
    if (data.icon) {
      this.userData = data
      this.tabs = getUserInfo().role === USER_TYPE_CUSTOM ? [_T('首页'), _T('审核')] : [_T('首页')]
    }
  }

  close() {
    this.show = false
  }

  goLogin() {
    return (e: any) => {
      e.stopPropagation()
      reportH5PayClick('点击登录')
      reportH5PayLoginWin('顶部banner')
      store.state.login_position = '顶部banner'
      if (!this.userData) {
        routerPush({ name: 'login', params: { pos: '顶部banner' } })
      }
    }
  }

  hover() {
    if (this.userData) {
      this.show = true
      setTimeout(() => {
        const left = (this.$refs.button as any).offsetLeft - 70
          ; (this.$refs.userBg as any).style.left = `${left}px`
          ; (this.$refs.userBg as any).style.display = 'flex'
      })
    }
  }

  notHover(e: any) {
    ; (this.$refs.userBg as any).style.display = 'none'
    this.show = false
  }

  show = false

  facebookQuit(res: any) {
    if (!res.session) {
      console.log('facebook注销成功')
    }
  }

  quit() {
    this.show = false
    this.userData = null
    this.$store.state.isLogin = false
    this.tabs = [_T('首页')]
    removeToken()
    removeUserInfo()
    removeLoginType()
    // 调一下谷歌跟facebook退出
    sdk.login.facebook.logOut(this.facebookQuit)
    sdk.login.google.logOut()
  }

  stop(e: any) {
    e.stopPropagation()
  }

  updateName() {
    if (this.userData || this.$store.state.isLogin) {
      if (!this.userData) {
        this.update()
      }
      return this.userData.nickname
    }
    return _T('登录')
  }

  goToHome() {
    routerPush({ name: 'index' })
  }

  render() {
    return (
      <BaseView class={styles.nav}>
        <BaseView
          // nativeOnClick={this.goToHome}
          class={styles.logoD}
        ></BaseView>
        {this.$store.state.nowRoute === '/index' ? (
          <BaseView class={styles.tabs}>
            {this.tabs.map((item, index) => this.renderTab(item, index))}
          </BaseView>
        ) : (
          ''
        )}
        {/* PC 登录入口 */}
        <BaseView class={styles.login} style="visibility: hidden;">
          <div class={styles.text} ref="button" onMouseover={this.hover} onClick={this.goLogin()}>
            {this.updateName()}
          </div>
        </BaseView>
        {this.userData && this.show ? (
          <div class={styles.box} ref="userBg" onClick={this.stop} onMouseleave={this.notHover}>
            <div class={styles.userBg}>
              <img src={changeBalootIcon(this.userData.icon)}></img>
              <div class={styles.nickname}>{this.userData.nickname}</div>
              <div class={styles.id}>{this.userData.display_uid}:ID</div>
              <div class={styles.domain}>
                {this.userData.coin}
                <span>
                  {process.env.VUE_APP_FLAVOR === 'are' ? _T('宝石余额') : _T('钻石余额')}
                </span>
              </div>
              <div class={styles.quit} onClick={this.quit}>
                {_T('退出')}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </BaseView>
    )
  }
}
export default loginNav
