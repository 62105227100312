/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { getPlatform } from '@badam/common-utils'
import {
  EventName,
  reportPayEvent,
  kRechargeSource,
  kLoginSource,
  kHomeClickElem,
  kLoginBtnPosition,
  kPaywayName,
  kPageName,
  kActionFrom,
  getActFrom,
  getAnonymousID,
} from './step-report'
import { getFinger, getLoginType, getToken, getUserInfo } from './token'

export function reportH5PayPageShow(page: kPageName) {
  reportPayEvent(EventName.h5_pay_page_show, {
    h5_deviceid: getFinger(),
    page,
    act_from: getActFrom(),
    login_source: getLoginType(),
    is_login: !!getToken(),
  })
}

export function reportH5PayClick(v: kHomeClickElem) {
  reportPayEvent(EventName.h5_pay_page_click, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: getLoginType(),
    is_login: !!getToken(),
    element_name: v,
  })
}

export function reportH5PayLoginWin(v: '顶部banner' | '充值按钮上方') {
  reportPayEvent(EventName.h5_pay_loginwin_show, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    is_login: !!getToken(),
    login_source: getLoginType(),
    position: v,
  })
}

export function reportH5PayLoginCall(loginType: kLoginSource, v?: kLoginBtnPosition) {
  reportPayEvent(EventName.h5_pay_login_call, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: loginType,
    is_login: !!getToken(),
    position: v,
  })
}

export function reportH5PayLoginSuccess(loginType: kLoginSource, v?: kLoginBtnPosition) {
  reportPayEvent(EventName.h5_pay_login_success, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: loginType,
    is_login: !!getToken(),
    position: v,
  })
}

export function reportH5PayLoginFail(
  loginType: kLoginSource,
  error_code: string,
  error_content: string,
  v?: kLoginBtnPosition
) {
  reportPayEvent(EventName.h5_pay_login_fail, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: loginType,
    is_login: !!getToken(),
    position: v,
    error_code,
    error_content,
  })
}

export function reportH5PayItemClick(amount: number) {
  reportPayEvent(EventName.h5_pay_item_click, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: getLoginType(),
    is_login: !!getToken(),
    amount,
  })
}

export function reportH5PayChannelClick(type: string) {
  reportPayEvent(EventName.h5_pay_channel_click, {
    h5_deviceid: getFinger(),
    page: '官网首页',
    act_from: getActFrom(),
    login_source: getLoginType(),
    is_login: !!getToken(),
    type: type as any,
  })
}

export async function getCommonReportData() {
  const data: {
    h5_deviceid: string
    act_from: kActionFrom
    login_source: any
    is_login: boolean
    cookie_id: any
  } = {
    h5_deviceid: getFinger(),
    act_from: getActFrom(),
    login_source: getLoginType(),
    is_login: !!getToken(),
    cookie_id: await getAnonymousID(),
  }

  return data
}
