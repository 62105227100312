/* eslint-disable no-shadow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
import {
  getCardSourceByOrder,
  getSourceIds,
  payType,
  removeSourceId,
  SourceId,
  SourceOrderRsp,
  uploadSourceId,
} from '@/api'
import { handleApiResult } from '@/api/config'
import store from '@/store'
import { setStorage, getStorage } from '@badam/common-utils'
import _ from 'lodash'
import { getFinger, getToken, getUid } from './token'

export enum FrameMethod {
  TOKEN = 1,
  SID = 2,
}

export enum RememberState {
  记住 = 1,
  不记住 = 2,
  初始化 = 0,
}

class SourceIdManager {
  static instance: SourceIdManager

  sidStorageKey = 'sawa_official_recharge_sid'

  lastOrderIdKey = 'last_order_id_sawa_official'

  rememberKey = 'remember_show_source_id'

  lastMethodKey = 'sid_last_method_key'

  whiteListKey = 'cko_new_white_list_key'

  lastSidPayKey = 'last_sid_pay_key'

  lastPayType = 'cko_new_last_pay_type_key'

  MAX_CARD_NUM = 3

  MAX_RETRY_TIMES = 60

  MAX_RETRY_DELAY = 2000

  static getInstance() {
    if (!this.instance) {
      this.instance = new SourceIdManager()
    }
    return this.instance
  }

  sleep(ts: number) {
    return new Promise((r) => {
      setTimeout(() => {
        r(true)
      }, ts)
    })
  }

  genRate() {
    const r = Math.floor(Math.random() * 100)
    return r
  }

  async getSids() {
    let realSids = await this.getSourceIdFromAll()
    if (!getToken()) {
      realSids = realSids.filter((s) => !s.login)
      realSids = realSids.filter((s) => {
        if (
          Date.now() - (s.time_stamp || 0) <
          store.state.pkgInfo.cko_card_record_time_limit * 60 * 1000
        ) {
          return true
        }
        return false
      })
    } else {
      realSids = realSids.filter((s) => !s.uid || (s.uid && s.uid === getUid()))
    }
    const sids = realSids.filter((s) => s.is_remember)
    store.state.sids = sids.slice(0, this.MAX_CARD_NUM)
    store.state.realSids = realSids.slice(0, this.MAX_CARD_NUM)
  }

  isInWhiteList() {
    let r
    const record = getStorage(this.whiteListKey)
    if (record) {
      r = record
    } else {
      r = this.genRate()
      setStorage(this.whiteListKey, r)
    }
    r = Number(r)
    const isInRate = r < store.state.pkgInfo.cko_new_rate
    const uid = Number(getUid() || 0)
    const isInWhite = store.state.pkgInfo.cko_new_whitelist.indexOf(uid) >= 0
    if (isInRate || isInWhite) {
      return true
    }
    return false
  }

  async getSourceIdByOrder(order_id: string, times = 0): Promise<{ sid: SourceId; error: string }> {
    const res = await getCardSourceByOrder(order_id)
    if (handleApiResult(res)) {
      const [, { data }] = res
      const sid = {
        ...data,
        h5_deviceid: getFinger(),
        is_remember: this.getRemember() !== RememberState.不记住,
        login: !!getToken(),
        uid: getUid(),
      }
      return { sid, error: '' }
    }
    if (times < this.MAX_RETRY_TIMES) {
      await this.sleep(this.MAX_RETRY_DELAY)
      return this.getSourceIdByOrder(order_id, times + 1)
    }
    this.setLastOrderId('')
    return { sid: {} as SourceId, error: (res[1] && res[1].message) || '' }
  }

  getSourceIdsFromLocal(): SourceId[] {
    let sids: SourceId[] = getStorage(this.sidStorageKey) || ([] as SourceId[])
    sids = sids.filter((s) => s.source_id)
    sids = sids.reverse()
    return sids
  }

  async getSourceIdsFromServer(): Promise<SourceId[]> {
    const res = await getSourceIds()
    if (handleApiResult(res)) {
      const [, { data }] = res
      const ditems = data.items || []
      const its = ditems.map((i) => ({
        ...i,
        login: true,
        uid: getUid(),
        is_remember: this.getRemember() !== RememberState.不记住,
      }))
      let gs = _.uniqBy(its, 'source_id')
      gs = _.uniqBy(gs, 'fingerprint')
      const items = gs
      console.warn('server groups', gs)
      return items as SourceId[]
    }
    return [] as SourceId[]
  }

  async getSourceIdFromAll() {
    const sidsLocal = this.getSourceIdsFromLocal()
    let sidsServer: SourceId[] = []
    if (getToken()) {
      sidsServer = await this.getSourceIdsFromServer()
    }
    const sids = [...sidsLocal, ...sidsServer]
    let gs = _.uniqBy(sids, 'source_id')
    gs = _.uniqBy(gs, 'fingerprint')
    const items = gs
    console.warn('all groups', gs)
    console.warn('all groups server', sidsServer)
    console.warn('all groups local', sidsLocal)
    return items as SourceId[]
  }

  async uploadSourceId() {
    const sids = this.getSourceIdsFromLocal().reverse()
    for (let i = 0; i < sids.length; i += 1) {
      const sid = sids[i]
      await uploadSourceId(
        sid.source_id,
        sid.last4,
        sid.bin,
        sid.scheme,
        sid.to_uid,
        sid.h5_deviceid,
        sid.is_remember || false,
        sid.fingerprint
      )
      await this.sleep(100)
    }
  }

  removeSidInSids(sid: SourceId) {
    const sids: SourceId[] = this.getSourceIdsFromLocal().reverse()
    const index = this.findSameFringerprintInSids(sid, sids)
    if (index >= 0) {
      sids.splice(index, 1)
      setStorage(this.sidStorageKey, sids)
      this.removeSidInSids(sid)
    }
  }

  async removeSourceId(sid: SourceId) {
    const res = await removeSourceId(sid.fingerprint)
    if (handleApiResult(res)) {
      this.removeSidInSids(sid)
    } else {
      this.removeSidInSids(sid)
    }
  }

  isInSids(d: SourceId, sids: SourceId[]) {
    for (let i = 0; i < sids.length; i += 1) {
      const sid = sids[i]
      if (d.source_id === sid.source_id) {
        return true
      }
    }
    return false
  }

  findSidIndexInSids(d: SourceId, sids: SourceId[]) {
    for (let i = 0; i < sids.length; i += 1) {
      const sid = sids[i]
      if (d.source_id === sid.source_id) {
        return i
      }
    }
    return -1
  }

  findSameFringerprintInSids(d: SourceId, sids: SourceId[]) {
    for (let i = 0; i < sids.length; i += 1) {
      const sid = sids[i]
      if (d.fingerprint === sid.fingerprint) {
        return i
      }
    }
    return -1
  }

  saveSourceId(sid: SourceId) {
    // eslint-disable-next-line no-param-reassign
    const d: SourceId = { ...sid, time_stamp: Date.now() }
    const sids: SourceId[] = this.getSourceIdsFromLocal().reverse()
    if (!this.isInSids(d, sids)) {
      sids.push(d)
      setStorage(this.sidStorageKey, sids)
    } else {
      const index = this.findSidIndexInSids(d, sids)
      sids.splice(index, 1)
      sids.push(d)
      setStorage(this.sidStorageKey, sids)
    }
  }

  setLastOrderId(order_id: string) {
    setStorage(this.lastOrderIdKey, order_id)
  }

  getLastOrderId() {
    return getStorage(this.lastOrderIdKey)
  }

  setLastFrameMethod(v: FrameMethod) {
    setStorage(this.lastMethodKey, v)
  }

  getLastFrameMethod(): FrameMethod {
    return getStorage(this.lastMethodKey) || FrameMethod.TOKEN
  }

  setRemember(v: RememberState) {
    setStorage(this.rememberKey, v)
  }

  getRemember(): RememberState {
    const s = getStorage(this.rememberKey)
    return s || RememberState.初始化
  }

  setLastSidPay(sid: SourceId | null) {
    setStorage(this.lastSidPayKey, sid)
  }

  getLastSidPay() {
    return getStorage(this.lastSidPayKey)
  }

  setLastPayType(v: payType) {
    setStorage(this.lastPayType, v)
  }

  getLastPayType() {
    return getStorage(this.lastPayType) || payType.Checkout
  }
}

const sourceIdManager = SourceIdManager.getInstance()

;(window as any).sourceIdManager = sourceIdManager
;(window as any)._ = _

export default sourceIdManager
