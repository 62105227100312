import { Component } from 'vue-property-decorator'
import { BaseComponent, BaseView } from '@badam/vue-components'
import './assets/css/index.module.scss'

interface Props { }

@Component({})
class App extends BaseComponent<Props> {
  render() {
    return (
      <BaseView id='app'>
        <router-view></router-view>
      </BaseView>
    )
  }
}

export default App
