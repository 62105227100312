import { Component } from 'vue-property-decorator'
import { BaseComponent, BaseView } from '@badam/vue-components'
import NavBar from '@/components/navbar/index.pc'
import Footer from '@/components/footer/index.pc'

interface Props { }

@Component({})
class App extends BaseComponent<Props> {
  render() {
    console.warn('App', this.$route)
    const rn = this.$route.name
    const enable = true
    return (
      <BaseView style="width:100%; height:100%;display:flex;flex-direction:column;align-items:center;" id='app'>
        {rn === 'pay' && enable ? null : <NavBar></NavBar>}
        <router-view></router-view>
        <Footer></Footer>
      </BaseView>
    )
  }
}

export default App
