/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/first */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue'
import '@badam/vue-tsx-support/enable-check'
import { customAlphabet } from 'nanoid'
import '@badam/vue-tsx-support/options/enable-nativeon'
import lang from './lang/index.kanka.json'
import { version, name, nanoid } from '../package.json'

function disableConsole() {
  if (!getArgsFromUrl('s_log')) {
    window.console = {
      ...window.console,
      log() {
        //
      },
      warn() {
        //
      },
      error() {
        //
      },
      info() {
        //
      },
    }
  }
}

disableConsole()

import {
  getPlatform,
  _T,
  setLang,
  getPerformance,
  exec,
  calculateWindow,
  ready,
  sdk,
  report2rum,
  getArgsFromUrl,
  initUmeng,
  initWebSensors,
  SensorSDKName,
  reportWebSensors,
  sprintf,
  textWithRTL,
  metric,
  calculateWindow4,
  initErrorReporter,
} from '@badam/common-utils'

// import sdk from '@badam/common-utils/dist/sdk'

if (getArgsFromUrlHash('lan') !== 'zh') {
  setLang(lang, process.env.VUE_APP_TRANSLATE_SHEET)
}

import AppPC from '@/App.pc'
import AppMobile from '@/App'

import router from './router'
import store, { setPkgInfo } from './store'
import '@/components/loading/loading'
import './common.scss'
import { getPkgInfo, reportToLark } from './api'
import { setTokenAndGetUserInfo } from './api/apiLogic'
import { getArgsFromUrlHash, goToApple3, goToGoogle3 } from './utils'
import { getFinger, getNanoid, getToken, setNanoid, setValue } from './utils/token'
import { EventName, getActFrom, reportPayEvent, withDefPayload } from './utils/step-report'
import updateManager from './utils/update-manager'
import { initRemFontSize } from './utils/ui'

Vue.config.productionTip = false
Vue.mixin({
  methods: { sprintf, _T, textWithRTL },
})

calculateWindow4()

initRemFontSize()

setNanoid()

let App: any

if (getPlatform().isPc) {
  App = AppPC
} else {
  App = AppMobile
}

function jumpSawa() {
  if ((window as any).JavaBridgeObj) {
    ;(window as any).JavaBridgeObj.setLoading(false)
  }
  setTimeout(() => {
    if (getArgsFromUrl('jumpAppStore')) {
      if (getPlatform().isAndroid) {
        goToGoogle3()
      } else {
        goToApple3()
      }
    }
  }, 1000)
}

exec(async () => {
  try {
    const res = await getPkgInfo()
    const [, data] = res
    let { result } = data.data
    result = JSON.parse(result)
    setPkgInfo(result.pkgInfo)
  } catch (e) {
    console.warn('e', e)
  }
  try {
    updateManager.check()
    jumpSawa()
    ready()
    sdk.login.facebook.init()
    sdk.login.google.init()
  } catch (e) {
    console.warn('e', e)
  }

  try {
    const token = getToken()
    console.warn('token', token)
    if (token) {
      await setTokenAndGetUserInfo(token)
    }
  } catch (e) {
    console.warn('e', e)
  }

  console.warn('PayManager', '当前的session key', getToken())
  // utils.exception.initErrorHandler()
  // 提早init，以免充值成功时间提交不到
  if (getArgsFromUrlHash('is_debug')) {
    await initWebSensors(SensorSDKName.TEST)
  } else if (location.href.indexOf('sawaksa.com') >= 0) {
    await initWebSensors(SensorSDKName.KSA)
  } else if (location.href.indexOf('sawa-are.com') >= 0) {
    await initWebSensors(SensorSDKName.ARE)
  } else {
    await initWebSensors(SensorSDKName.TEST)
  }

  initErrorReporter(name, nanoid)

  // 初始化语言
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
  // reportToLark(`初始化页面 session Key: ${getToken()}`, `${location.href}\n nanoid:${getNanoid()}`)
  reportWebSensors('act_sawa_official_website_init_page', {
    act_init_url: location.href,
    act_nano_id: getNanoid(),
    h5_deviceid: getFinger(),
    websilte_platform: getPlatform().isPc ? 'pc' : 'mobile',
  })
  reportPayEvent(EventName.h5_page_did_appear, {
    h5_deviceid: getFinger(),
  })
  try {
    metric.observeSourceMetric(
      [
        {
          desc: 'FCP',
          source_name: 'first-contentful-paint',
        },
      ],
      parseInt(version.split('.').join('') as string, 10) as number,
      'web'
    )
  } catch (e) {
    console.warn('e', e)
  }
})

function banScale() {
  document.documentElement.addEventListener(
    'touchstart',
    (event) => {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    },
    false
  )

  let lastTouchEnd = 0
  document.documentElement.addEventListener(
    'touchend',
    (event) => {
      const now = Date.now()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
}

banScale()

// reload if platform changed
let isPlatformPc = getPlatform().isPc
window.addEventListener('resize', (e) => {
  if (isPlatformPc !== getPlatform().isPc) {
    console.log('reload.')
    isPlatformPc = getPlatform().isPc
    window.location.reload()
  }
})

// window.onload = function () {
//   // 阻止双击放大
//   let lastTouchEnd = 0
//   document.addEventListener('touchstart', (event) => {
//     if (event.touches.length > 1) {
//       event.preventDefault()
//     }
//   })
//   document.addEventListener(
//     'touchend',
//     (event) => {
//       const now = new Date().getTime()
//       if (now - lastTouchEnd <= 300) {
//         event.preventDefault()
//       }
//       lastTouchEnd = now
//     },
//     false
//   )

//   // 阻止双指放大
//   document.addEventListener('gesturestart', (event) => {
//     event.preventDefault()
//   })
// }
