/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import store from '@/store'
import {
  getNanoid,
  getToken,
  getUserInfo,
  removeToken,
  removeUserInfo,
  setToken,
  setUserInfo,
} from '@/utils/token'
import { reportWebSensors } from '@badam/common-utils'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { showReplacePop } from '@/views/recharge/new-mobile/replace-account/index.vue'
import sourceIdManager from '@/utils/source-id-manager'
import {
  loginIn,
  getInfo,
  facebookLoginIn,
  googleLoginIn,
  reportToLark,
  ticketLoginIn,
} from './index'

export const isIMToken = (v: string) => {
  if (!v) {
    return false
  }
  return v.slice(0, 3) === 'OW_'
}

export const setTokenAndGetUserInfoCaseTicket = (session_key: string) =>
  new Promise((resolve, reject) => {
    console.warn('PayManager', '客户端登录态', session_key)
    getInfo(session_key).then((res: any) => {
      console.warn('PayManager', '客户端登录态 登录之后', session_key, res[0], res[1].result)
      if (res[0] && res[1].result === 0) {
        // 1. 若已登录
        if (getToken()) {
          const user = getUserInfo()
          // 1.1 已登录的是官网登录，则不顶掉
          // if (!isIMToken(getToken())) {
          //   resolve({ ...res[1].data, session_key })
          //   return
          // }
          if (res[1].data.uid === user.uid) {
            setUserInfo({ ...res[1].data, session_key })
            setToken(session_key)
            if (window.sensors) {
              window.sensors.login(`${res[1].data.uid}`)
            }
            // 返回用户信息给他做渲染
            resolve(res[1].data)
          } else {
            showReplacePop({
              name1: user.nickname,
              name2: res[1].data.display_uid || res[1].data.display_id,
              cb1: () => {
                // 2. 否则，替换登录态
                setUserInfo({ ...res[1].data, session_key })
                setToken(session_key)
                if (window.sensors) {
                  window.sensors.login(`${res[1].data.uid}`)
                }
                // 返回用户信息给他做渲染
                resolve(res[1].data)
              },
              cb2: () => {
                resolve(user)
              },
            })
          }
          // 1.2 已登录的是IM登录的，要顶掉；同无登录态情况
        } else {
          setUserInfo({ ...res[1].data, session_key })
          setToken(session_key)
          if (window.sensors) {
            window.sensors.login(`${res[1].data.uid}`)
          }
          // 返回用户信息给他做渲染
          resolve(res[1].data)
        }

        store.state.isLogin = true
      } else {
        removeUserInfo()
        removeToken()
        store.state.isLogin = false

        reject(new Error(`登录发生错误!${session_key}`))
      }
    })
  })

export const setTokenAndGetUserInfo = (session_key: string) =>
  new Promise((resolve, reject) => {
    getInfo(session_key).then((res: any) => {
      if (res[0] && res[1].result === 0) {
        setUserInfo({ ...res[1].data, session_key })
        setToken(session_key)
        if (window.sensors) {
          window.sensors.login(`${res[1].data.uid}`)
        }
        // 返回用户信息给他做渲染
        resolve(res[1].data)
        store.state.isLogin = true
        sourceIdManager.uploadSourceId()
      } else {
        removeUserInfo()
        removeToken()
        store.state.isLogin = false

        reject(new Error(`登录发生错误!${session_key}`))
      }
    })
  })

// 账号密码登录，成功设置cookie，然后拿用户信息直接返回
export const autoLogin = (userName: string, password: string) =>
  new Promise((resolve, reject) => {
    loginIn(userName, password).then((res: any) => {
      if (res[0] && res[1].result === 0) {
        console.log(res)
        if (res[1].message) {
          console.log(res[1].message)
          resolve({ message: res[1].toast || res[1].message })
        } else {
          const { session_key } = res[1].data
          setTokenAndGetUserInfo(session_key).then((userInfo: any) => {
            resolve(userInfo)
          })
          report(session_key, true, 'account')
        }
      } else {
        report('', false, 'account', userName, password)
        reject(new Error('登录失败'))
      }
    })
  })

// 跟上面一样，不过走facebook了
export const facebookLogin = (userId: string, accessToken: string) =>
  new Promise((resolve, reject) => {
    facebookLoginIn(userId, accessToken).then((res: any) => {
      if (res[0] && res[1].result === 0) {
        if (res[1].message) {
          resolve({ message: res[1].message })
        } else {
          const { session_key } = res[1].data
          setTokenAndGetUserInfo(session_key).then((userInfo: any) => {
            resolve(userInfo)
          })
          report(session_key, true, 'facebook')
        }
      }
    })
  })

// 跟上面一样，不过走gogole了
export const googleLogin = (token: string) =>
  new Promise((resolve, reject) => {
    googleLoginIn(token).then((res: any) => {
      if (res[0] && res[1].result === 0) {
        if (res[1].message) {
          resolve({ message: res[1].message })
        } else {
          const { session_key } = res[1].data
          setTokenAndGetUserInfo(session_key).then((userInfo: any) => {
            resolve(userInfo)
          })
          report(session_key, true, 'google')
        }
      }
    })
  })

// 端内私信过来
export const ticketLogin = (token: string) =>
  new Promise((resolve, reject) => {
    const start = Date.now()
    console.log('PayManager', 'ticket login 发起请求', start, token)
    ticketLoginIn(token).then((res: any) => {
      const end = Date.now()
      console.log(
        'PayManager',
        'ticket login 请求返回',
        end,
        end - start,
        token,
        res[1].result,
        res[1].requestId
      )
      if (res[0]) {
        const { result } = res[1]
        if (result === 0) {
          if (res[1].message) {
            resolve({ message: res[1].message })
          } else {
            const { session_key } = res[1].data
            setTokenAndGetUserInfoCaseTicket(session_key).then((userInfo: any) => {
              sourceIdManager.uploadSourceId()
              resolve(userInfo)
            })
            report(session_key, true, 'ticket')
          }
        } else {
          resolve({ message: res[1].message || res[1].result })
        }
      }
      if (!res[0]) {
        resolve({ message: res[1] || 'req error' })
      }
    })
  })

function report(session_key: string, result: boolean, style: string, user?: string, pwd?: string) {
  reportWebSensors('act_sawa_official_website_login', {
    act_init_url: location.href,
    act_session_key: session_key,
    act_result: result,
    act_login_style: style,
    act_user_name: user || '',
    act_pwd: pwd || '',
    act_nano_id: getNanoid(),
  })
  // let str = ''
  // if (!result) {
  //   str = `用户名：${user}\n 密码：${pwd}\n`
  // }
  // reportToLark(
  //   '登录是否成功',
  //   `${result}\n 登录方式：${style}\n ${str} session_key: ${session_key} \n nanoid:${getNanoid()}`
  // )
}
