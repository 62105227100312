/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-globals */
import store from '@/store'
import { getArgsFromUrl } from '@badam/common-utils'
import axios from 'axios'
import { getWebsiteVersion } from '.'
import { EventName, reportPayEvent } from './step-report'
import { getFinger } from './token'

export class UpdateManager {
  static instance: UpdateManager

  static getInstance() {
    if (!this.instance) {
      this.instance = new UpdateManager()
    }
    return this.instance
  }

  replaceParamVal(replaceWith: string) {
    const oUrl = location.href.toString()
    const re = /(dt=)([^&]*)/g
    const nUrl = oUrl.replace(re, `dt=${replaceWith}`)
    return nUrl
  }

  check() {
    this.checkUpdate()
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkUpdate()
      }
    })
  }

  sleep(d: number) {
    return new Promise((r) => {
      setTimeout(() => {
        r(true)
      }, d)
    })
  }

  pullWebsiteByAxiosWithNoCache(url: string) {
    return axios.get(url, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
  }

  async checkUpdate() {
    const { pkgInfo } = store.state
    if (this.isNowLowerThanConfig(pkgInfo.website_version, getWebsiteVersion())) {
      const u = location.href
      let r = '?'
      if (u.indexOf('?') >= 0) {
        r = '&'
      } else {
        r = '?'
      }
      const d = Date.now()
      await reportPayEvent(EventName.h5_page_update, { h5_deviceid: getFinger() })
      await this.sleep(1000)
      let url = ''
      if (getArgsFromUrl('dt')) {
        url = this.replaceParamVal(`${d}`)
      } else {
        url = `${location.href}${r}dt=${d}`
      }
      await this.pullWebsiteByAxiosWithNoCache(url)
      location.replace(url)
    }
  }

  isNowLowerThanConfig(ver: string, curVersion: string) {
    const version = `${ver}`
    const v = parseInt(version.split('.').join(''), 10)
    const cv = parseInt(curVersion.split('.').join(''), 10)
    console.warn('版本对比', `最新版本: ${version}`, `当前版本: ${curVersion}`)
    return cv < v
  }
}

const updateManager = UpdateManager.getInstance()

export default updateManager
