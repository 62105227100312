/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */

import { onMounted, onUnmounted, ref } from 'vue'
import Loading from '../views/recharge/new-mobile/loading/index.vue'

/* eslint-disable @typescript-eslint/no-empty-function */
const baseWidth = 720
const divideTie = 50

export function initRemFontSize() {
  const maxWidth = window.screen.width < 600 ? window.screen.width : 600
  document.documentElement.style.fontSize = `${maxWidth / divideTie}px`
}

export function xp(p: number) {
  return `${((parseFloat(document.documentElement.style.fontSize) * divideTie) / baseWidth) * p}px`
}

export function xpx(p: number) {
  return ((parseFloat(document.documentElement.style.fontSize) * divideTie) / baseWidth) * p
}

export function getVisualHeight() {
  return window.visualViewport?.height || document.documentElement.clientHeight
}

export function getKeyboardHeight() {
  return document.documentElement.clientHeight - (window.visualViewport?.height || 0)
}

export function useKeyboardHeight() {
  const h = ref(0)
  let timer = 0
  const delay = 100

  function setKeyBoardHeight() {
    clearInterval(timer)
    timer = setInterval(() => {
      h.value = getKeyboardHeight()
    }, delay)
  }

  onMounted(() => {
    setKeyBoardHeight()
  })

  onUnmounted(() => {
    clearInterval(timer)
  })

  return h
}
