/* eslint-disable no-use-before-define */
/* eslint-disable indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */

import store from '@/store'
import { getArgsFromUrl, reportWebSensors, getPlatform } from '@badam/common-utils'
import { getArgsFromUrlHash, getWebsiteVersion } from '.'
import { getSStorage, ThisIsTicket } from './pay-manager'
import sourceIdManager from './source-id-manager'
import * as token from './token'

export type kPayTypeName =
  | 'google'
  | 'paypal'
  | 'applepay'
  | 'payermax'
  | 'coda'
  | 'dlocal'
  | 'khalaspay'
  | '其他'

// export type kPaywayName = 'checkout' | 'payermax'
export type kPaywayName = kPayTypeName

export type kRechargeSource = '官网充值' | '官网缴纳罚款'

export type kLoginSource =
  | 'account'
  | 'phone'
  | 'apple'
  | 'google'
  | 'facebook'
  | 'app内跳转带上登录态'
  | '' // 未登录报空字符串

export type kPageName = '官网首页' | '支付页面' | '支付成功页面' | '支付失败页面' | string

export type kActionFrom = 'app内打开' | 'app内跳转' | '移动端浏览器' | 'PC浏览器'

export type kHomeClickElem =
  | '点击充值'
  | '点击登录'
  | '点击appstore'
  | '点击谷歌商店'
  | '点击apk下载'
  | '点击华为商店'
  | '点击切换充值账户'

export type kLoginBtnPosition = '顶部banner' | '充值按钮上方' | ''

/* eslint-disable camelcase */
export enum EventName {
  // 产品定义事件
  h5_pay_page_show = 'h5_pay_page_show',
  h5_pay_page_exit = 'h5_pay_page_exit',
  h5_pay_page_click = 'h5_pay_page_click',
  h5_pay_loginwin_show = 'h5_pay_loginwin_show',
  h5_pay_login_call = 'h5_pay_login_call',
  h5_pay_login_success = 'h5_pay_login_success',
  h5_pay_login_fail = 'h5_pay_login_fail',
  h5_pay_item_click = 'h5_pay_item_click',
  h5_pay_channel_click = 'h5_pay_channel_click',
  recharge_fail = 'recharge_fail',
  recharge_success = 'recharge_success',
  click_recharge = 'click_recharge',
  displayuid_operate = 'displayuid_operate',
  h5_create_pay_result = 'h5_create_pay_result',
  h5_pay_get_saved_card_result = 'h5_pay_get_saved_card_result',
  h5_pay_confirm_halfpage_show = 'h5_pay_confirm_halfpage_show',
  h5_pay_saved_card_click = 'h5_pay_saved_card_click',
  h5_pay_saved_card_delete = 'h5_pay_saved_card_delete',
  h5_pay_cardinfo_fill_halfpage = 'h5_pay_cardinfo_fill_halfpage',
  h5_pay_cardinfo_save_pop_result = 'h5_pay_cardinfo_save_pop_result',
  h5_pay_step = 'h5_pay_step',

  // 技术内部自查事件
  h5_page_did_appear = 'h5_page_did_appear',
  h5_page_update = 'h5_page_update',
  h5_pay_cko_card_record_pull = 'h5_pay_cko_card_record_pull',
}

export type H5_PAY_PAGE_SHOW_PAYLOAD = {
  h5_deviceid: string
  page: kPageName
  act_from: kActionFrom
  login_source: kLoginSource
  is_login: boolean
  position?: string
}

export type H5_PAY_PAGE_EXIT_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  duration: number
}

export type H5_PAY_PAGE_CLICK_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  element_name: kHomeClickElem
}

export type H5_PAY_LOGINWIN_SHOW_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  position?: kLoginBtnPosition
}

export type H5_PAY_LOGIN_CALL_PAYLOAD = H5_PAY_LOGINWIN_SHOW_PAYLOAD

export type H5_PAY_LOGIN_SUCCESS_PAYLOAD = H5_PAY_LOGIN_CALL_PAYLOAD

export type H5_PAY_LOGIN_FAIL_PAYLOAD = H5_PAY_LOGIN_SUCCESS_PAYLOAD & {
  error_code: string
  error_content: string
}

export type H5_PAY_ITEM_CLICK_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  amount: number
}

export type H5_PAY_CHANNEL_CLICK_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  type: kPaywayName
}

export type RECHARGE_SUCCESS_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  duration: number
  relationship?: string
  is_first_h5_deviceid?: boolean
  is_first?: boolean
  other_id: number
  source: kRechargeSource
  type: kPaywayName
  number: number
  amount: number
}

export type RECHARGE_FAIL_PAYLOAD = RECHARGE_SUCCESS_PAYLOAD & {
  fail_reason: string
  fail_detail: string
}

export type CLICK_RECHARGE_PAYLOAD = RECHARGE_SUCCESS_PAYLOAD & {
  website_version: string
  is_saved_card?: boolean
}

export type DISPLAYUID_OPERATE_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  operate:
    | '输入'
    | '提交'
    | '默认填充登录账号'
    | '默认填充上次充值账号'
    | '手动选择登录账号'
    | '手动选择最近充值账号'
  is_paste: boolean
}

export type H5_CREATE_PAY_RESULT = H5_PAY_PAGE_SHOW_PAYLOAD & {
  duration: number
  is_succeed: boolean
  fail_reason: string
  source: string
  type: string
  cko_type?: 'sid' | 'token'
  is_saved_card?: boolean
  cko_card_gray?: boolean
}

export type H5_PAY_CKO_CARD_RECORD_PULL_PAYLOAD = H5_PAY_PAGE_SHOW_PAYLOAD & {
  duration: number
  is_succeed: boolean
}

export type H5_PAY_GET_SAVED_CARD_RESUlT = H5_PAY_PAGE_SHOW_PAYLOAD & {
  duration: number
  is_success: boolean
  error_message: string
}

export type H5_PAY_CONFIRM_HALFPAGE_SHOW = H5_PAY_PAGE_SHOW_PAYLOAD & {
  useable_card_num: number
  saved_card_num: number
}

export type H5_PAY_SAVED_CARD_CLICK = H5_PAY_PAGE_SHOW_PAYLOAD & {
  source: string
}

export type H5_PAY_SAVED_CARD_DELETE = H5_PAY_PAGE_SHOW_PAYLOAD & {
  page: string
}

export type H5_PAY_CARDINFO_FILL_HALFPAGE = H5_PAY_PAGE_SHOW_PAYLOAD & {
  source: string
  operation_type: string
}

export type H5_PAY_CARDINFO_SAVE_POP_RESULT = H5_PAY_PAGE_SHOW_PAYLOAD & {
  is_auto_agreed: boolean
  is_agreed: boolean
}

export type H5_PAY_STEP = H5_PAY_PAGE_SHOW_PAYLOAD & {
  is_saved_card: boolean
  step: string
}

export type EventMap = {
  [EventName.h5_pay_page_show]: H5_PAY_PAGE_SHOW_PAYLOAD
  [EventName.h5_pay_page_exit]: H5_PAY_PAGE_EXIT_PAYLOAD
  [EventName.h5_pay_page_click]: H5_PAY_PAGE_CLICK_PAYLOAD
  [EventName.h5_pay_loginwin_show]: H5_PAY_LOGINWIN_SHOW_PAYLOAD
  [EventName.h5_pay_login_call]: H5_PAY_LOGIN_CALL_PAYLOAD
  [EventName.h5_pay_login_success]: H5_PAY_LOGIN_SUCCESS_PAYLOAD
  [EventName.h5_pay_login_fail]: H5_PAY_LOGIN_FAIL_PAYLOAD
  [EventName.h5_pay_item_click]: H5_PAY_ITEM_CLICK_PAYLOAD
  [EventName.h5_pay_channel_click]: H5_PAY_CHANNEL_CLICK_PAYLOAD
  [EventName.recharge_fail]: RECHARGE_FAIL_PAYLOAD
  [EventName.recharge_success]: RECHARGE_SUCCESS_PAYLOAD
  [EventName.click_recharge]: CLICK_RECHARGE_PAYLOAD
  [EventName.displayuid_operate]: DISPLAYUID_OPERATE_PAYLOAD
  [EventName.h5_create_pay_result]: H5_CREATE_PAY_RESULT
  [EventName.h5_pay_get_saved_card_result]: Partial<H5_PAY_GET_SAVED_CARD_RESUlT>
  [EventName.h5_pay_confirm_halfpage_show]: Partial<H5_PAY_CONFIRM_HALFPAGE_SHOW>
  [EventName.h5_pay_saved_card_click]: Partial<H5_PAY_SAVED_CARD_CLICK>
  [EventName.h5_pay_saved_card_delete]: Partial<H5_PAY_SAVED_CARD_DELETE>
  [EventName.h5_pay_cardinfo_fill_halfpage]: Partial<H5_PAY_CARDINFO_FILL_HALFPAGE>
  [EventName.h5_pay_cardinfo_save_pop_result]: Partial<H5_PAY_CARDINFO_SAVE_POP_RESULT>
  [EventName.h5_pay_step]: Partial<H5_PAY_STEP>
  /** ------ 华丽的分割线 -------- */
  [EventName.h5_page_did_appear]: Partial<H5_PAY_PAGE_SHOW_PAYLOAD>
  [EventName.h5_page_update]: Partial<H5_PAY_PAGE_SHOW_PAYLOAD>
  [EventName.h5_pay_cko_card_record_pull]: Partial<H5_PAY_CKO_CARD_RECORD_PULL_PAYLOAD>
}

export async function reportPayEvent<T extends keyof EventMap>(name: T, payload: EventMap[T]) {
  try {
    const p = {
      ...payload,
      cko_card_gray: sourceIdManager.isInWhiteList(),
      website_anonymous_id: await getAnonymousID(),
      website_nano_id: token.getNanoid(),
      website_version: getWebsiteVersion(),
      website_cur_url: location.href,
      website_platform: getPlatform().isPc ? 'pc' : 'mobile',
      h5_deviceid: token.getFinger(),
      cookie_id: await getAnonymousID(),
      act_from: getActFrom(),
      login_source: getLoginType(),
      is_login: !!token.getToken(),
    }
    console.info('[report]', name, p as any)
    reportWebSensors(name, p as any)
  } catch (e) {
    console.warn('error report', e)
  }
}

export function getLoginType() {
  return token.getLoginType()
}

export function getActFrom() {
  if (getArgsFromUrlHash('ticket') || getSStorage(ThisIsTicket)) {
    return 'app内跳转'
  }
  return window.JavaBridgeObj ? 'app内打开' : !getPlatform().isPc ? '移动端浏览器' : 'PC浏览器'
}

export function getLoginPosition(): '顶部banner' | '充值按钮上方' | '' {
  return store.state.login_position
}

export function withDefPayload<T extends keyof EventMap>(payload: Partial<EventMap[T]>) {
  return {
    h5_deviceid: token.getFinger(),
    login_source: getLoginType(),
    is_login: !!token.getToken(),
    ...payload,
  }
}

export function getAnonymousID() {
  return new Promise((r) => {
    if ((window as any).sensors) {
      ;(window as any).sensors.quick('isReady', () => {
        const anonymousID = (window as any).sensors.quick('getAnonymousID')
        r(anonymousID)
      })
    } else {
      r(token.getNanoid())
    }
  })
}
