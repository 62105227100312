import Loading from '@/components/loading/loading.vue'

export const showLoading = (bl: boolean) => {
  if (bl) {
    const div = document.createElement('div')
    const el = new Loading().$mount(div)
    document.body.appendChild(el.$el)
  } else {
    const el = document.getElementById('loading')
    if (el) {
      document.body.removeChild(el)
    }
  }
}

export default {}
