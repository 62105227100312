
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line import/no-duplicates, import/first
import Vue, { defineComponent, defineProps } from 'vue'
import { _T, sprintf } from '@badam/common-utils'

const ReplaceAccount = Vue.extend({
  data() {
    return {
      show: false,
      name1: '',
      name2: '',
      cb1: (() => {}) as any,
      cb2: (() => {}) as any,
    }
  },
  methods: {
    _T,
    sprintf,
    cc1() {
      this.cb1()
      this.show = false
    },
    cc2() {
      this.cb2()
      this.show = false
    },
  },
})

let el: any
let loading: any

export function showReplacePop(o: { name1: string; name2: string; cb1: any; cb2: any }) {
  if (!el) {
    el = document.createElement('div')
    document.body.appendChild(el)
    loading = new ReplaceAccount({ el })
  }

  loading.name1 = o.name1
  loading.name2 = o.name2
  loading.cb1 = o.cb1
  loading.cb2 = o.cb2
  loading.show = true
}

export function hideLoading() {
  loading.show = false
}

export default ReplaceAccount
