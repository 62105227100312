import { Component } from 'vue-property-decorator'
import { BaseComponent } from '@badam/vue-components'
import { _T } from '@badam/common-utils'
import { goToPrivate } from '@/utils'
import styles from './pc.module.scss'

interface Props {}

@Component({})
class footer extends BaseComponent<Props> {
  url: any = {
    are: 'https://arabpk-game.badambiz.com/html/about_en.html',
    ksa: ''
    // TODO: ksa地址
  }

  render() {
    return (
      <div class={styles.footer}>
        <div class={styles.list}>
          <div class={[styles.line, styles.hover]} onClick={goToPrivate}>
            {_T('隐私政策')}
          </div>
          <div class={styles.line}>{_T('关于我们')}</div>
          <div class={styles.line}>{_T('联系我们')}</div>
        </div>
        <div class={styles.copyright}>Copyright@2023 ZIIPIN TECHNOLOGY HK LIMITED</div>
      </div>
    )
  }
}
export default footer
